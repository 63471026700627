import {
  CollectionGraphDataMonth,
  CollectionPointsData,
  LineChartDataItem,
  User,
  DoughnutChartItem,
} from "../types";
import { Languages, monthsArray, WasteTypes } from "../utils";

export const mockedUser: User = {
  id: 1,
  name: "František Žebř",
  language: Languages.sk,
  city: "Prievidza",
  wcpid: 123456,
  devices: [],
  administrationName: "Prievidza",
  administrationNameHungarian: "Prievidza",
};

export const mockedCollectionPointsInfo: CollectionPointsData = {
  location: "Prievidza",
  collectionPoints: [
    {
      name: "Prievidza Juh",
      date: new Date(2020, 1, 10).toString(),
      weight: 105.4,
      price: 65.48,
      checked: true,
      materials: [WasteTypes.plastics, WasteTypes.paper, WasteTypes.glass, WasteTypes.mixed],
    },
    {
      name: "Prievidza",
      date: new Date(2020, 11, 8).toString(),
      weight: 17.4,
      price: 412.78,
      checked: true,
      materials: [WasteTypes.plastics, WasteTypes.paper],
    },
    {
      name: "Prievidza",
      date: new Date(2020, 10, 22).toString(),
      weight: 22.1,
      price: 220.44,
      checked: false,
      materials: [WasteTypes.glass, WasteTypes.mixed],
    },
    {
      name: "Prievidza Juh",
      date: new Date(2020, 9, 19).toString(),
      weight: 644,
      price: 27.87,
      checked: true,
      materials: [WasteTypes.glass, WasteTypes.collectionyard, WasteTypes.mixed],
    },
  ],
};

export const mockedNotificationsInfoBoxDate = "30. 3. 2023";

const mockedLineChartData: LineChartDataItem[] = [];
export { mockedLineChartData };

const mockedWasteCollectionMonthly: CollectionGraphDataMonth = [];
export { mockedWasteCollectionMonthly };

export const mockedDoughnutGraphData: DoughnutChartItem[] = [
  {
    wasteType: WasteTypes.plastics,
    percentages: 32,
    bgColor: "#f4c311",
  },
  {
    wasteType: WasteTypes.paper,
    percentages: 21,
    bgColor: "#0682fe",
  },
  {
    wasteType: WasteTypes.glass,
    percentages: 10,
    bgColor: "#4ab51d",
  },
];

export const monthsIndexArray = monthsArray.map((_, i) => String(i + 1));

export const mockedOfficialContacts = [
  {
    name: "Martin Novák",
    position: "Starosta",
    phone: "+ 421 764 987 341",
    email: "martin.novak@prievidza.sk",
  },
  {
    name: "Renáta Zamľavská",
    position: "Místostarostka",
    phone: "+ 421 792 648 913",
    email: "Renata.zamlavska@prievidza.sk",
  },
  {
    name: "René Borkovič",
    position: "Odbor dopravy",
    phone: "+ 421 645 374 132",
    email: "rene.borkovic@prievidza.sk",
  },
];
