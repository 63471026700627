import { useTranslation } from "react-i18next";
import styles from "./MyEwaCard.module.scss";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../../../selectors";
import { Icon } from "../../Icon";
import { Icons } from "../../../utils";
import { useGetCollectionYardQrCodeUrlQuery } from "../../../api/citizenApi";

export const MyEwaCard = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const { data: qrCodeUrl } = useGetCollectionYardQrCodeUrlQuery();

  if (!qrCodeUrl) return null;

  let administration = user?.administrationName;
  if (user?.administrationNameHungarian) {
    administration += ` | ${user?.administrationNameHungarian}`;
  }

  return (
    <>
      <Divider />
      <div className={styles.container}>
        <h4 className={styles.title}>{t("yourEwaCard")}</h4>
        <p className={styles.text}>{t("yourEwaCardDescription")}</p>
        <div className={styles.card}>
          <div className={styles.header}>
            <Icon style={{ width: "32px" }} name={Icons.ewaLogoSquare} />
            <div>
              <h4 className={styles.user}>{user?.name}</h4>
              <p className={styles.wcpid}>{user?.wcpid}</p>
            </div>
          </div>
          <div className={styles.banner}>
            <Icon style={{ width: "100%" }} name={Icons.ewaCardPattern} />
            <div className={styles["qr-wrapper"]}>
              <img className={styles["qr-code"]} src={qrCodeUrl} alt="qr code" />
            </div>
          </div>
          <div className={styles.footer}>
            <p>{administration}</p>
          </div>
        </div>
      </div>
    </>
  );
};
